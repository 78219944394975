.task-loading {
  background: #3f4254;
  border-radius: 100px;
  position: relative;
  overflow: hidden;
  min-height: 21px;
}

.task-loading-anim {
  animation: load 1.5s linear infinite;
  background: linear-gradient(to right, #3f4254, #787a87, #3f4254);
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  transform: skewX(-40deg);
}

@keyframes load {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
