.tasks-gantt-chart {
  --vis-label-size: 180px;
  --vis-border-radius: 5px;

  gap: 0.5rem;

  &,
  &__actions,
  &__container {
    position: relative;
    display: flex;
    width: 100%;
  }

  &,
  &__container {
    flex-direction: column;
    height: 100%;
  }

  &__actions {
    opacity: 0;
  }

  &__container {
    position: relative;
    padding: 0.5rem 0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .vis-timeline {
    &-skeleton {
      position: absolute;
      top: 0.5rem;
      bottom: 0.5rem;
      left: 0.5rem;
      right: 0.5rem;

      transition: opacity 0.5s;
      opacity: 0;
      z-index: 0;

      &.loading {
        opacity: 1;
        z-index: 2;
      }

      @import "vis-timeline_skeleton";
    }

    &-container {
      z-index: 1;
      width: calc(100% - 1rem);
      height: 100%;

      transition: opacity 1s;
      opacity: 1;

      &.loading {
        opacity: 0;
      }

      @import "vis-timeline";
    }
  }
}

@import "task-overlay";
