
.react-datepicker.custom-datepicker {
  display: flex;

  .react-datepicker {
    &__month-container {
      width: 250px;
      font-size: 1rem;
      border: none;
    }

    &__header {
      background: $primary-light !important;
      padding-top: 4px;
    }

    &__month-select {
      padding: 4px;

      font-size: 1.1rem !important;
      border: 1px solid #e1dfe0;
      border-radius: 0.45rem;
    }

    &__year-select {
      padding: 4px;
      font-size: 1.1rem !important;
      border: 1px solid #e1dfe0;
      border-radius: 0.45rem;
    }

    &__navigation-icon {
      color: $white !important;
    }

    &__day {
      &--keyboard-selected {
        background: $primary !important;
      }

      &--selected {
        background: $primary !important;
      }

      &--in-selecting-range:not(&__day--in-range) {
        background: lighten($primary, 10%) !important;
        //color: $dark !important;
      }

      &--in-range {
        background: $primary !important;
      }
    }

    &__current-month {
      color: $black;
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 8px;
    }

    &__time-list-item--selected {
      background-color: $primary !important;
    }

    &__close-icon::after {
      background-color: transparent;
      color: $secondary;
      font-size: 24px;
    }
  }
}

.react-datepicker.date-range-picker {
  border-radius: 0;
  border: 0;

  .react-datepicker__header {
    border-color: $secondary;
    background-color: $gray-200 !important;
  }
}
