.kyc-file-upload {
  .dropdown-toggle.btn.btn-outline-secondary {
    flex-grow: 1;
    text-align: left;
    overflow: hidden;
    padding-right: -24px;
  }

  .btn.btn-outline-secondary:not(:disabled):not(.disabled):active:not(.btn-text),
  .btn.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn.btn-outline-secondary.dropdown-toggle,
  .show .btn.btn-outline-secondary.btn-dropdown {
    flex-grow: 1;
    text-align: left;
    overflow: hidden;
  }

  .btn.btn-outline-secondary.dropdown-toggle:after {
    display: none;
  }

  .dropdown-indicator {
    position: absolute;
    right: 12px;
  }
}
