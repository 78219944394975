//
// Vis Timeline
//

// General
.vis-timeline {
  @import "vars__vis-timeline";

  --innerVisBorderColor: #{$color_hex__vis-border};
  --innerVisLabelSize: var(--visLabelSize, #{$vis-label-size});

  //---//

  border: 1px solid var(--innerVisBorderColor);

  //---//

  &,
  .vis-item {
    border-radius: $vis-border-radius;
  }

  .vis-labelset {
    .vis-label {
      display: flex;
      align-items: center;

      min-width: var(--innerVisLabelSize);
      max-width: var(--innerVisLabelSize);

      padding-left: 0.5rem;
      padding-right: 0.5rem;

      border-bottom: 1px dashed var(--innerVisBorderColor);

      color: var(--visLabelsetLabelColor, #{$color_hex__vis-label});

      .vis-inner {
        padding-left: 5px;

        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.vis-group-level-1 {
        background-color: var(--visGroupLevel1, #{$color_hex__vis-label__group-level-1});
      }

      &.vis-group-level-2 {
        background-color: var(--visGroupLevel2, #{$color_hex__vis-label__group-level-2});
      }

      &.vis-group-level-2,
      &.vis-group-level-1:not(.vis-nesting-group) {
        .vis-inner {
          padding-left: 20px;
        }
      }

      &.vis-nesting-group {
        &.collapsed {
          &:before {
            content: "▸";
          }
        }

        &.expanded {
          &:before {
            content: "▾";
          }
        }
      }
    }
  }

  .vis-foreground {
    .vis-group {
      border-bottom: 1px dashed var(--innerVisBorderColor);
    }
  }

  .vis-item {
    position: absolute;
    color: var(--visItemColor, #{darken($color_hex__planned, 70)});
    background-color: var(--visItemBackgroundColor, #{rgb($color_hex__planned, 0.2)});
    border: 1px solid var(--visItemBorderColor, #{darken($color_hex__planned, 10)});

    .vis-item-overflow {
      display: flex;
      align-items: center;
    }

    .vis-item-content {
      padding: 0.4rem 0.5rem;
    }

    &:not(.background) {
      &.PLANNED {
        --visItemColor: #{darken($color_hex__planned, 70)};
        --visItemBorderColor: #{darken($color_hex__planned, 15)};
        --visItemBackgroundColor: #{$color_hex__planned};

        &:hover {
          --visItemBackgroundColor: #{lighten($color_hex__planned, 5)};
        }

        &.vis-selected {
          --visItemColor: #{darken($color_hex__planned, 70)};
          --visItemBorderColor: #{darken($color_hex__planned, 25)};
          --visItemBackgroundColor: #{darken($color_hex__planned, 5)};
        }
      }

      &.IN_PROGRESS {
        --visItemColor: #{darken($color_hex__in-progress, 25)};
        --visItemBorderColor: #{lighten($color_hex__in-progress, 1)};
        --visItemBackgroundColor: #{lighten($color_hex__in-progress, 40)};

        &:hover {
          --visItemBackgroundColor: #{lighten($color_hex__in-progress, 45)};
        }

        &.vis-selected {
          --visItemColor: #{darken($color_hex__in-progress, 30)};
          --visItemBorderColor: #{darken($color_hex__in-progress, 10)};
          --visItemBackgroundColor: #{lighten($color_hex__in-progress, 35)};
        }
      }

      &.STUCK {
        --visItemColor: #{darken($color_hex__stuck, 20)};
        --visItemBorderColor: #{lighten($color_hex__stuck, 10)};
        --visItemBackgroundColor: #{lighten($color_hex__stuck, 30)};

        &:hover {
          --visItemBackgroundColor: #{lighten($color_hex__stuck, 35)};
        }

        &.vis-selected {
          --visItemColor: #{darken($color_hex__stuck, 25)};
          --visItemBorderColor: #{darken($color_hex__stuck, 15)};
          --visItemBackgroundColor: #{lighten($color_hex__stuck, 25)};
        }
      }

      &.DONE {
        --visItemColor: #{darken($color_hex__done, 20)};
        --visItemBorderColor: #{lighten($color_hex__done, 8)};
        --visItemBackgroundColor: #{lighten($color_hex__done, 40)};

        &:hover {
          --visItemBackgroundColor: #{lighten($color_hex__done, 45)};
        }

        &.vis-selected {
          --visItemColor: #{darken($color_hex__done, 25)};
          --visItemBorderColor: #{darken($color_hex__done, 15)};
          --visItemBackgroundColor: #{lighten($color_hex__done, 30)};
        }
      }
    }

    &.vis-selected:not(.background) {
      --visItemColor: var(--visItemSelectedColor, #495057);
      --visItemBorderColor: var(--visItemSelectedBorderColor, #ffc107);
      --visItemBackgroundColor: var(--visItemSelectedBackgroundColor, #fff785);
    }

    &.background {
      --endGradientPertentage: 98%;

      --innerVisItemBackgroundOpacity: var(--visItemBackgroundOpacity, 0);

      --innerVisBgBorderLeftColor: var(--visBgBorderLeftColor, #{darken($color_hex__planned, 5)});
      --innerVisBgBorderColor: var(--visBgBorderColor, #{darken($color_hex__planned, 10)});
      --innerVisBgBackgroundColor: var(--visBgBackgroundColor, #{rgb($color_hex__planned, 0.3)});
      --innerVisBgBackground: var(
        --visBgBackground,
        linear-gradient(
          to right,
          #{rgb($color_hex__planned, 0.3)},
          #{rgb($color_hex__planned, 0.3)} var(--progress),
          transparent var(--endGradientPertentage)
        )
      );

      &.IN_PROGRESS {
        --visBgBorderLeftColor: #{$color_hex__in-progress};
        --visBgBorderColor: #{darken($color_hex__in-progress, 10)};
        --visBgBackgroundColor: #{rgb($color_hex__in-progress, 0.08)};
        --visBgBackground: linear-gradient(
          to right,
          #{rgb($color_hex__in-progress, 0.1)},
          #{rgb($color_hex__in-progress, 0.1)} var(--progress),
          transparent 95%
        );
      }

      &.STUCK {
        --visBgBorderLeftColor: #{$color_hex__stuck};
        --visBgBorderColor: #{darken($color_hex__stuck, 10)};
        --visBgBackgroundColor: #{rgb($color_hex__stuck, 0.06)};
        --visBgBackground: linear-gradient(
          to right,
          #{rgb($color_hex__stuck, 0.2)},
          #{rgb($color_hex__stuck, 0.2)} var(--progress),
          transparent var(--endGradientPertentage)
        );
      }

      &.DONE {
        --visBgBorderLeftColor: #{$color_hex__done};
        --visBgBorderColor: #{darken($color_hex__done, 10)};
        --visBgBackgroundColor: #{rgb($color_hex__done, 0.08)};
        --visBgBackground: linear-gradient(
          to right,
          #{rgb($color_hex__done, 0.1)},
          #{rgb($color_hex__done, 0.1)} var(--progress),
          transparent var(--endGradientPertentage)
        );
      }

      z-index: 0;
      border-radius: 3px;

      border-left-color: var(--innerVisBgBorderLeftColor);
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
      border-right-width: 0;

      transition: opacity 0.5s;
      opacity: var(--innerVisItemBackgroundOpacity);
      background: var(--innerVisBgBackgroundColor);
      background: var(--innerVisBgBackground);

      &:before {
        content: "";

        position: absolute;
        top: 50%;

        border-right-width: 0;
        border-bottom: 1px solid var(--visBgTimelineBorderColor, var(--innerVisBgBorderColor));

        width: 100%;
      }

      &:after {
        content: "";
        position: absolute;

        top: -3px;
        bottom: -3px;
        left: 0;

        width: 0;

        border-right: 1px solid var(--visBgProgressBorderColor, var(--innerVisBgBorderColor));
      }

      &[data-progress="0"] {
        --progress: 1%;
        &:after {
          border-right-color: transparent;
          border-right-width: 0;
        }
      }

      @for $progress from 1 through 100 {
        &[data-progress="#{$progress}"] {
          --progress: #{$progress * 1%};
          &:after {
            width: var(--progress);
          }
        }
      }
    }
  }

  .vis-time-axis {
    text-transform: uppercase;
    font-size: var(--visTimeAxisFontSize, calc(1rem * 0.875));

    .vis-text {
      color: var(--visTimeAxisTextColor, #{$color_hex__vis-axis-label});
    }

    .vis-grid {
      &.vis-minor {
        border-left-color: var(--innerVisBorderColor);
      }

      &.vis-vertical {
        border-left: 1px dashed var(--innerVisBorderColor);
      }
    }
  }

  .vis-panel {
    &.vis-bottom,
    &.vis-center,
    &.vis-left,
    &.vis-right,
    &.vis-top {
      border-color: var(--innerVisBorderColor);
    }

    .vis-shadow {
      box-shadow: none !important;
    }
  }

  .vis-current-time {
    width: 1px;
    background-color: var(--visCurrentTimeColor, #{$color_hex__vis-current-time});
  }
}
