.step-header {
  text-align: right;
}

.step-content-center {
  min-height: 50vh;
}

.step-content-center-maximized {
}
.step-content {
  margin: 12px auto auto;
  padding-bottom: 20px;
  min-height: 500px;
  max-width: 1200px;
  height: fit-content;

  &.maximized {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    margin: 48px auto auto;
    padding: 24px;

    min-height: 80% !important;
  }
}

.step-container {
  min-height: 60vh;
  padding: 16px;

  &.maximized {
    height: inherit;
  }
}

.carousel {
  height: inherit;
}

.stepper-indicator {
  padding-top: 12px;
  min-height: 300px;
  height: 100%;
  background-color: white;
}

.card-maximized {
  border-radius: 0 !important;
  max-width: 100vw;
  padding: 0;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100% !important;
  z-index: 1000;
}

.carousel.slide {
  height: inherit;
}

.carousel-inner {
  height: 100% !important;
}

.carousel-item {
  height: 100%;
  overflow-x: hidden;
}

.kyc-card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 90px;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 4px, rgba(0, 0, 0, 0.15) 0px 0px 4px;
  margin-bottom: 3px;
  padding: 2rem 2.25rem;
  height: 90px;
}

.kyc-card-title {
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.kyc-comment-highlighted {
  border: 1px solid darkorange;
}

.content-item {
  padding: 30px 0;
  background-color: #ffffff;
}

.content-item.grey {
  background-color: #f0f0f0;
  padding: 50px 0;
  height: 100%;
}

.content-item h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  text-transform: uppercase;
  margin: 20px 0;
}

.content-item h3 {
  font-weight: 400;
  font-size: 20px;
  color: #555555;
  margin: 10px 0 15px;
  padding: 0;
}

.content-headline {
  height: 1px;
  text-align: center;
  margin: 20px 0 70px;
}

.content-headline h2 {
  background-color: #ffffff;
  display: inline-block;
  margin: -20px auto 0;
  padding: 0 20px;
}

.grey .content-headline h2 {
  background-color: #f0f0f0;
}

.content-headline h3 {
  font-size: 14px;
  color: #aaaaaa;
  display: block;
}

#comments {
  box-shadow: 0 -1px 6px 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

#comments form {
  margin-bottom: 30px;
}

#comments .btn {
  margin-top: 7px;
}

#comments form fieldset {
  clear: both;
}

#comments form textarea {
  height: 100px;
}

#comments .media {
  border-top: 1px dashed #dddddd;
  padding: 20px 0;
  margin: 0;
}

#comments .media > .pull-left {
  margin-right: 20px;
}

#comments .media img {
  max-width: 100px;
}

#comments .media h4 {
  margin: 0 0 10px;
}

#comments .media h4 span {
  font-size: 14px;
  float: right;
  color: #999999;
}

#comments .media p {
  margin-bottom: 15px;
  text-align: justify;
}

#comments .media-detail {
  margin: 0;
}

#comments .media-detail li {
  color: #aaaaaa;
  font-size: 12px;
  padding-right: 10px;
  font-weight: 600;
}

#comments .media-detail a:hover {
  text-decoration: underline;
}

#comments .media-detail li:last-child {
  padding-right: 0;
}

#comments .media-detail li i {
  color: #666666;
  font-size: 15px;
  margin-right: 10px;
}
