@use "sass:list";
@use "sass:meta";
@use "sass:string";
@import "src/_metronic/_assets/sass/components/_variables.kodehyve.scss";

.iconTags {
  svg {
    fill: $gray-500 !important;
  }
}
.tags {
  .danger > i.fas {
    color: $danger;
  }
}
.tags-container {
  display: contents !important;
}

@media (max-width: 856px) {
  .tags-container {
    justify-content: start !important;
  }
}
@media (max-width: 424px) {
  .tags {
    min-width: 100% !important;
  }
}
