@function solidify($color, $background) {
  $alpha: alpha($color);
  $red: red($color);
  $green: green($color);
  $blue: blue($color);
  $bg-red: red($background);
  $bg-green: green($background);
  $bg-blue: blue($background);
  $red: ($red * $alpha) + ($bg-red * (1 - $alpha));
  $green: ($green * $alpha) + ($bg-green * (1 - $alpha));
  $blue: ($blue * $alpha) + ($bg-blue * (1 - $alpha));
  @return rgb($red, $green, $blue);
}

// src/_metronic/_assets/sass/components/utilities/_background.scss
// We need a solid color instead of a transparent one in order to using frozen columns
@each $color, $value in $theme-colors {
  @for $opacity from 1 through 20 {
    .bg-#{$color}-o-#{$opacity * 5}-solid {
      background-color: solidify(rgba($value, ($opacity * 20 * 0.001)), #fff) !important;
    }

    .bg-hover-#{$color}-o-#{$opacity}-solid {
      transition: $transition;
      cursor: pointer;

      &:hover {
        transition: $transition;
        background-color: solidify(rgba($value, $opacity * 20 * 0.01), #fff) !important;
      }
    }

    .hoverable:hover {
      .bg-hover-#{$color}-o-#{$opacity}-solid {
        transition: $transition;
        background-color: solidify(rgba($value, $opacity * 20 * 0.01), #fff) !important;
      }
    }
  }
}

// Handle gray backgrounds with hoverable parents
@each $color, $value in $grays {
  .hoverable:hover {
    .bg-hover-gray-#{$color} {
      transition: $transition;
      background-color: $value !important;
    }
  }
}


.svg-icon {
  // Theme colors
  &.svg-icon-tertiary {
    @include svg-icon-color(#5E6EC9, true);
  }

  &.svg-icon-quaternary {
    @include svg-icon-color(#56CBF9, true);
  }

  &.svg-icon-quinary {
    @include svg-icon-color(#C6846D, true);
  }
}
