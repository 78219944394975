.task-lane-item {
  --taskItemOpacityValue: var(--taskItemOpacity, 1);
  --transitionTaskLaneItemOpacityValue: opacity 200ms;

  position: relative;
  opacity: var(--taskItemOpacityValue);
  transition: var(--transitionTaskLaneItemOpacityValue);

  background-color: var(--darkenLaneBg, #ececec);

  &,
  &--subitems {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  &---header {
    padding-top: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 12px;
    color: var(--laneItemHeaderTextColor, #565656);

    &.ellipsis {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--subitems {
    padding: 0 0.3rem 0.4rem 0.3rem;
  }
}
