.kyc-validation-element {
  border: 1px solid red;
}

.kyc-validation-element-button-container {
}

.kyc-validation-element-hover-container {
  //border: 1px solid yellow;
  display: flex;
  justify-content: flex-end;
  background-color: #1d5cff;
  left: 0px;
  right: -96px;
  position: absolute;
  z-index: 0;
  //width: 100px;
  //height: 20px;
}
