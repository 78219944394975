.task-item {
  --taskItemOpacityValue: var(--taskItemOpacity, 1);

  position: relative;
  opacity: var(--taskItemOpacityValue);

  flex-direction: column;
  min-height: 88px;

  .task-item-title {
    margin-right: 1.2rem;
    font-size: 13px;
    font-weight: 400;

    .ellipsis {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .task-item-assignee {
    font-size: 11px;
  }

  .task-item-close-button {
    display: none;
    position: absolute;
    right: 2px !important;
    top: 4px !important;
  }

  .task-item-close-button-icon:hover {
    color: #e8534f;
  }

  &:hover {
    .task-item-close-button {
      display: inline;
    }
  }

  .bottom-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;

    .gantt-chart {
      color: var(--iconGanttChartColor, inherit);
    }
  }

  .task-text-timeago {
    background-color: transparent;
    gap: 0.25rem;

    font-size: 12px;
    font-weight: 500;

    min-height: 19px;

    align-items: center;

    &,
    &--icon,
    &--label {
      display: flex;
    }

    &--icon {
      align-items: flex-end;
    }
  }
}
