.info {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;

  // TODO: review
  // align-items: center;
  // gap: 0.5rem;

  &-label {
    color: var(--color-display-info-label);

    font-weight: 500;
    font-size: 0.9rem;
  }

  &-value {
    color: var(--color-display-info-value);

    font-size: 0.95rem;
  }
}
