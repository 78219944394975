.parentField {
  --lightGrayColor: #ebecf0;

  &__container {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    padding: 2px;

    &:hover {
      outline-offset: -10px;
      background-color: var(--lightGrayColor) !important;
    }
  }

  &__name {
    font-size: 1rem;
    flex-grow: 1;
    line-height: 1.5;
    outline: none;
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 0.8rem;
    box-shadow: none;
    color: black;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__status {
    z-index: 3;
    outline: none;
  }
}
