* {
  box-sizing: border-box;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-horizontal-scrolling-menu--item:first-child {
  //margin-left: 50px;
}
