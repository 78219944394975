@import "../../../../_metronic/_assets/sass/init";

.dropdown-select-toggler {
  &__control {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 0.42rem;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40px;
    position: relative;
    transition: all 100ms;

    &::after {
      display: none
    }

    &:hover {
      border-color: hsl(0, 0%, 70%);

      .dropdown-select-toggler__indicator-container {
        color: hsl(0, 0%, 70%);
      }
    }

    &[aria-expanded="true"] {
      border-color: $primary;

      .dropdown-select-toggler__indicator-container {
        color: hsl(0, 0%, 40%);

      }
    }
  }

  &__value-container {
    align-items: center;
    display: grid;
    flex: 1;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  &__indicators-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
  }

  &__indicator-container {
    color: hsl(0, 0%, 80%);
    display: flex;
    padding: 8px;
    transition: color 150ms;
    font-size: 13px;

    &:hover {
      color: hsl(0, 0%, 70%);
    }
  }

  &__indicator-separator {
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
  }

}
