@import "../../../../../../_metronic/_assets/sass/init";

.kyc-button-group-button {
  padding: 6px;
  text-align: center;
  margin-left: 2px;

  &-selected {
    background: $primary;
    text-align: center;
    color: white;
  }
}

.kyc-button-group-button-selected:hover {
  color: white;
}
