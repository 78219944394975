.validate-identity-modal {
  button {
    &:disabled {
      cursor: not-allowed;
    }

    &.btn-confirm {
      min-width: 115px;
    }
  }
}
