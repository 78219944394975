.request-signature-dialog-body {
  .collection {
    position: relative;
    overflow: auto;
    padding-right: 8px;

    .gradient {
      position: sticky;
      z-index: 2;
      width: 100%;
      left: 0;
      right: 0;
      min-height: var(--collection-gradient-height);

      --color-rgb: 245, 245, 245;
      --gradient-colors: rgba(var(--color-rgb), 0.5) 0%, rgba(var(--color-rgb), 0.2) 45%,
        transparent 100%;

      &-before {
        top: var(--collection-header-min-height);
        background-image: linear-gradient(to bottom, var(--gradient-colors));
      }

      &-after {
        bottom: 0;
        background-image: linear-gradient(to top, var(--gradient-colors));
      }
    }
  }
}
