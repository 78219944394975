.modal-title .status {
  padding: 0.2rem 1.5rem;
  border-radius: 50vh;

  background-color: transparent;
  color: var(--color-text-status);

  &.status {
    &-needed {
      background-color: var(--color-status-needed);
    }

    &-in-progress {
      background-color: var(--color-status-in-progress);
    }

    &-done {
      background-color: var(--color-status-done);
    }
  }
}
