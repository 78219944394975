.custom-select-status-option {
  .fas {
    width: 1.5rem;

    text-shadow: none;

    color: var(--iconOptionColor, var(--iconLocalColor));
  }

  &:active {
    background-color: transparent;
  }
}
