.projectOwnerDisplay {
  --border-color: var(--color-gray-300);
  --focus-color: var(--color-primary);

  margin: 0.5rem;
  width: 100%;

  &,
  .control {
    display: flex;
    align-items: center;
  }

  .control {
    &,
    .text {
      flex-grow: 1;
    }

    border: 1px solid var(--border-color);
    border-radius: 0.42rem;

    /* top | right | bottom | left */
    padding: 0.5rem 0 0.5rem 0.5rem;

    &:not(.readonly) {
      cursor: pointer;

      &:hover {
        border: 1px solid var(--focus-color);

        .svg-icon svg g [fill] {
          transition: fill 0.3s ease;
          fill: var(--focus-color) !important;
        }
      }
    }

    .avatar {
      margin-right: 0.5rem;
    }

    .separator {
      height: 100%;
      min-height: 21px;
      width: 1px;
      margin: 0 2px;
      box-sizing: border-box;
      background-color: var(--border-color);
    }

    .svg-icon {
      padding: 0 8px;
    }
  }
}

.projectOwnerSelection .rbt-aux .rbt-close {
  margin-top: 0;
}

.projectOwnerInfoIcon {
  cursor: help;
}
