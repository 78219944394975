.subtask-item {
  --lightGrayColor: #ebecf0;

  display: flex;
  gap: 0.5rem;

  align-items: center;

  padding: 0.5rem;
  min-height: 3.6rem;

  border-radius: 3px;

  &:hover {
    background-color: var(--lightGrayColor);

    .actions {
      display: flex;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--lightGrayColor);
  }

  .name,
  .edit-name {
    flex: 1;
  }

  .name {
    color: initial;

    &.disabled {
      text-decoration: none;
      cursor: initial;
      pointer-events: none;
    }

    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }

    &,
    .text-ellipsis {
      overflow: hidden;
    }

    .text-ellipsis {
      display: block;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .actions {
    display: none;
    gap: 2px;

    &.editing {
      display: flex;
    }

    & > [type="button"] {
      padding: 0;
      border: 1px solid lightgray;
      border-radius: 0.42rem;
      padding: 2px;
    }
  }

  .status {
    display: flex;
    justify-content: flex-end;

    font-size: 12px;
    i {
      font-size: 13px;
    }
  }
}
