/*
  WARNING: css variable defined on the React Component

  breaking points
    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

  https://getbootstrap.com/docs/4.5/layout/overview/#responsive-breakpoints


  grid-template-rows | CSS-Tricks - CSS-Tricks
  https://css-tricks.com/almanac/properties/g/grid-template-rows/
*/

.signatories {
  $breakpoint-md: 768px;
  $breakpoint-md-max: 991.98px;
  $breakpoint-lg: 992px;
  $breakpoint-lg-max: 1199.98px;
  $breakpoint-xl: 1200px;

  .h5 {
    padding-bottom: 1rem;
  }

  &,
  .notifications,
  .collection,
  .item-header,
  .item,
  .actions {
    display: flex;
  }

  &,
  .collection {
    flex-direction: column;
  }

  .notifications {
    justify-content: flex-end;
    align-items: center;
  }

  .collection {
    min-height: calc(var(--collection-header-min-height) + (var(--collection-item-min-height) * 3));
    max-height: calc(var(--collection-header-min-height) + (var(--collection-item-min-height) * 5));

    .item-header {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: var(--color-header-bg);
    }

    @media (max-width: $breakpoint-md-max) {
      grid-template-columns: 1fr;
    }

    @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-lg-max) {
      grid-template-columns: 1fr 1fr;

      &.empty {
        grid-template-columns: 1fr;
      }
    }

    @media (max-width: $breakpoint-lg-max) {
      display: grid;
      grid-gap: 1.5rem;
      grid-auto-rows: minmax(max-content, 210px);

      padding: 1rem 0;

      height: calc(var(--collection-header-min-height) + 215px);

      &.empty {
        grid-template-rows: auto;
      }

      .item-header,
      .gradient {
        display: none;
        visibility: hidden;
      }

      .item:not(.item-header) {
        border: 1px solid var(--color-border);
        border-radius: 0.5rem;
        padding: 1rem;
      }

      .item.empty {
        justify-content: center;
        align-items: center;

        .attribute {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .item-header {
    color: var(--color-header-text);
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    min-height: var(--collection-header-min-height);

    .name:after {
      content: var(--label-name);
    }

    .email:after {
      content: var(--label-email);
    }

    .phone:after {
      content: var(--label-phone);
    }

    .actions:after {
      content: var(--label-actions);
    }
  }

  .item {
    --attribute-label-size: 0.8rem;

    @media (max-width: $breakpoint-lg-max) {
      gap: 0.5rem;
      display: flex;
      flex-direction: column;
      position: relative;

      .attribute {
        display: grid;
        grid-gap: 0.5rem;

        &:before {
          place-self: center start;

          color: var(--color-header-text);
          font-weight: 600;
          font-size: var(--attribute-label-size);
        }
      }

      .avatar {
        justify-content: start;
      }

      .name:before {
        content: var(--label-name);
      }

      .email:before {
        content: var(--label-email);
      }

      .phone:before {
        content: var(--label-phone);
      }

      .name,
      .email,
      .phone {
        &:after {
          content: attr(data-error);
        }
      }

      .form-fields {
        .name,
        .email,
        .phone {
          &:after {
            min-height: 15px;
            color: var(--color-danger);
            font-size: var(--attribute-label-size);
            content: attr(data-error);
          }
        }
      }

      .actions {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        .btn-remove:hover i {
          color: var(--color-danger);
        }
      }
    }

    @media (min-width: $breakpoint-xl) {
      align-items: center;
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 5rem 2fr 2fr 14rem 5rem;

      &:not(.item-header, .empty) {
        &:hover {
          color: var(--color-hover-text);
          background-color: var(--color-hover-bg);
        }
      }

      &:not(.empty) {
        border-bottom: 1px solid var(--color-border);
      }

      &.last {
        border-bottom: none;
      }

      .attribute {
        padding: 2px;
      }

      &.empty {
        margin: 1rem 0;
        flex-grow: 1;
      }

      .empty,
      .avatar,
      .actions {
        display: flex;
        justify-content: center;
        height: 100%;
      }

      .form-fields {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 2fr 2fr 14rem;
        grid-column: 2 / span 3;

        .name,
        .email,
        .phone {
          &:after {
            display: block;
            min-height: 18px;
            padding-top: 2px;
            color: var(--color-danger);
            font-size: var(--attribute-label-size);
            content: attr(data-error);
          }
        }
      }

      .attribute {
        &.actions > *:not(:last-child) {
          margin-right: 0.5rem;
        }

        &.empty {
          grid-column: 1 / -1;
          align-items: center;
          padding: 0.5rem;
        }
      }

      &:not(.item-header) {
        min-height: var(--collection-item-min-height);

        .avatar,
        .name,
        .email,
        .phone,
        .actions {
          /* top | right | bottom | left */
          padding: 0.5rem 0.5rem 0.5rem 0;
        }
      }

      .email {
        overflow-wrap: anywhere;
      }
    }
  }

  & > .actions {
    min-height: 45px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;

    .add-row {
      flex-grow: 1;
    }
  }
}
