.text-long {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 25ch;
  max-width: 50ch;

  &.inline {
    max-width: 25ch;
  }
}

.file-explorer-entry {
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
}

.file-explorer-entry-inline {
  font-size: 12px;
  cursor: pointer;

  &.inline {
    max-width: 150px;
  }
}
