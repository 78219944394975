.card-table {
  // Classes to make shadow visible only on one side
  .overflow-shadow-start:after {
    content: "";
    display: block;
    position: absolute;
    right: -8px;
    top: 0;
    width: 8px;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    opacity: 1;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  }

  .overflow-shadow-end:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    z-index: 10;
    pointer-events: none;
    opacity: 1;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  // Override of the bootstrap classes in order to make the sticky component works inside an accordion
  // the collapsing classes using an overflow hidden by default and this cause to break the sticky child
  // Instead of this we use the clip-path property to imitate the overflow hidden when the height is collapsing/expanding
  .collapse {
    &:not(.show) {
      display: block;
      height: 0;
      overflow: hidden;
      clip-path: inset(0px 0px 100% 0px);
    }
  }

  .collapsing {
    overflow: visible;
    clip-path: inset(0px 0px 0px 0px);
  }
}
