.skeleton {
  @import "vars__vis-timeline";

  height: 100%;
  display: grid;
  grid-template-rows: min(44px) 1fr min(44px);
  grid-template-columns: min(#{$vis-label-size}) 1fr;
  grid-template-areas: "hl hp" // hl - header left | hp - header panel
    "cl cp" // cl - center labels | cp - center panel
    "fl fp" // fl - foot left | fp - footpanel
;

  border-radius: $vis-border-radius;

  &,
  .center-labels,
  .center-panel {
    border: 1px solid $color_hex__vis-border;
  }

  .header-left,
  .footer-left {
    border-right: 1px solid $color_hex__vis-border;
  }

  .header {
    &-left {
      grid-area: hl;
    }

    &-panel {
      grid-area: hp;
    }
  }

  .center {
    &-labels {
      grid-area: cl;
      background-color: $color_hex__vis-label__group-level-0;

      border-left: 0;
    }

    &-panel {
      grid-area: cp;

      border-left: 0;
      border-right: 0;

      padding: 1rem;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .footer {
    &-left {
      grid-area: fl;
    }

    &-panel {
      grid-area: fp;
    }
  }
}
