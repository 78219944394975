.subtasks {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.6rem;

    .title {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
      align-self: end;

      margin: 0;
    }
    .actions {
      grid-row: 1 / 1;
      grid-column: 2 / 2;
      justify-self: end;
    }
    .progressbar {
      grid-row: 2 / 2;
      grid-column: 1 / 1;
      align-self: center;
    }
    .progress-status {
      grid-row: 2 / 2;
      grid-column: 2 / 2;
      justify-self: end;

      display: flex;
      gap: 0.5rem;
      margin-right: 2px;

      .number {
        display: flex;
        gap: 3px;
        justify-content: flex-end;

        min-width: 3rem;

        &.percentage {
          &::after {
            content: " %";
          }
        }
      }
    }
  }

  &__body {
    margin-top: 1.5rem;
  }

  &__add-new {
    margin-top: 1rem;
  }
}
