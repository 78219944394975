.custom-single-value {
  .ellipsis {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .fas {
    width: 1.5rem;
  }
}
