.task-overlay {
  @import "vars__vis-timeline";

  display: flex;
  flex-direction: column;

  &__status {
    margin-bottom: 0.5rem;

    padding: 0.5rem 0;
    border: 1px solid
      var(--statusBorderColor, var(--statusColor, #{darken($color_hex__planned, 5)}));
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--statusColor, #{darken($color_hex__planned, 50)});
    background-color: var(--statusBackgroundColor, #{lighten($color_hex__planned, 5)});

    .fas {
      padding: 0;
      margin: 0;
      margin-right: 0.5rem;
      color: var(--statusIconColor, #{darken($color_hex__planned, 10)});
      font-size: 11px;
    }

    .text {
      padding-top: 2px;
    }

    &.IN_PROGRESS {
      --statusColor: #{darken($color_hex__in-progress, 20)};
      --statusBorderColor: #{darken($color_hex__in-progress, 1)};
      --statusBackgroundColor: #{rgb($color_hex__in-progress, 0.08)};
      --statusIconColor: #{darken($color_hex__in-progress, 10)};
    }

    &.STUCK {
      --statusColor: #{darken($color_hex__stuck, 20)};
      --statusBorderColor: #{darken($color_hex__stuck, 1)};
      --statusBackgroundColor: #{rgb($color_hex__stuck, 0.06)};
      --statusIconColor: #{darken($color_hex__stuck, 10)};
    }

    &.DONE {
      --statusColor: #{darken($color_hex__done, 20)};
      --statusBorderColor: #{darken($color_hex__done, 1)};
      --statusBackgroundColor: #{rgb($color_hex__done, 0.08)};
      --statusIconColor: #{darken($color_hex__done, 10)};
    }
  }

  &__name {
    margin: 0.5rem 0;
    padding: 0 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid darken($color_hex__planned, 10);
    font-size: 13px;
  }

  &__info {
    margin: 0.5rem;

    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1rem;
    align-self: center;

    .info-label {
      justify-self: end;
    }

    .planned-label {
      color: darken($color_hex__planned, 55);
      margin-top: 1rem;
    }
  }
}
