@import "../../../../../../_metronic/_assets/sass/init";

.control {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 1rem;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: $white;
  border: 1px solid $gray-300;
  border-radius: 0;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: $white;
}

.control input:checked ~ .control_indicator {
  background: $primary;
  border: 1px solid $primary;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: $primary;
}
.control input:disabled ~ .control_indicator {
  opacity: 0.8;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-radio-padding-left {
  left: 12px;
}
.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 4px;
  top: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: $white;
  transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: $white;
}
.control-radio .control_indicator::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: $white;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}
