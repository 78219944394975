#notifications {
  z-index: 50;
  position: fixed;
  right: 12px;
  bottom: 8vh;
  width: 300px;
  .fade {
    animation: opacity 0.3s linear;
  }
}
