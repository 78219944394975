:root {
  --color-page-bg: #f7f5f0;
  --color-primary: #cea87b;
  --color-primary-hover: #bb8d61;
  --color-primary-light: #f7f5f0;
  --color-primary-inverse: #ffffff;
  --color-success: #50ab9f;
  --color-success-hover: #339088;
  --color-success-light: #F1F9F7;
  --color-success-inverse: #ffffff;
  --color-warning: #ffa800;
  --color-warning-hover: #ee9d01;
  --color-warning-light: #fff4de;
  --color-warning-inverse: #ffffff;
  --color-danger: #e8534f;
  --color-danger-hover: #cb3129;
  --color-danger-light: #F9DDDC;
  --color-danger-inverse: #ffffff;
  --color-info: #6e6db2;
  --color-info-hover: #585996;
  --color-info-light: #F3F3F7;
  --color-info-inverse: #ffffff;
  --color-gray-100: #F5F5F5;
  --color-gray-200: #F5F5F5;
  --color-gray-300: #E0E0E0;
  --color-gray-400: #D6D6D6;
  --color-gray-500: #B8B8B8;
  --color-gray-600: #7A7A7A;
  --color-gray-700: #5C5C5C;
  --color-gray-800: #333333;
  --color-gray-900: #1B1B1B;
}

$page-bg: #f7f5f0;
$primary: #cea87b;
$primary-hover: #bb8d61;
$primary-light: #f7f5f0;
$primary-inverse: #ffffff;
$success: #50ab9f;
$success-hover: #339088;
$success-light: #F1F9F7;
$success-inverse: #ffffff;
$warning: #ffa800;
$warning-hover: #ee9d01;
$warning-light: #fff4de;
$warning-inverse: #ffffff;
$danger: #e8534f;
$danger-hover: #cb3129;
$danger-light: #F9DDDC;
$danger-inverse: #ffffff;
$info: #6e6db2;
$info-hover: #585996;
$info-light: #F3F3F7;
$info-inverse: #ffffff;
$gray-100: #F5F5F5;
$gray-200: #F5F5F5;
$gray-300: #E0E0E0;
$gray-400: #D6D6D6;
$gray-500: #B8B8B8;
$gray-600: #7A7A7A;
$gray-700: #5C5C5C;
$gray-800: #333333;
$gray-900: #1B1B1B;
