/*
  CKEditor 5 - Content Styles
  https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
*/

.texteditor {
  // fix the table layout to avoid the column resize
  .ck-content .table table {
    overflow: hidden;
    table-layout: fixed;
  }
}
