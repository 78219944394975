.emptyStateContainer {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyStateTaskPage {
  position: absolute !important;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 4rem 0 4rem;
}

.emptyStatePages {
  margin: 4rem 0 4rem;
  text-align: center;
}
