.sorter {
  .direction-btn {
    --innerBorderColor: var(--borderColor, transparent);
    --innerBorderRightColor: var(--borderRightColor, transparent);
    --innerBackgroundColor: var(--backgroundColor, white);

    border-radius: 0.42rem 0 0 0.42rem;
    border: 1px solid var(--innerBorderColor) !important;
    border-right-color: var(--innerBorderRightColor) !important;

    background-color: var(--innerBackgroundColor) !important;

    min-height: 40px;
    min-width: 47px;

    &:disabled {
      pointer-events: none;

      --innerBackgroundColor: rgba(0, 0, 0, 0.08);
      --innerBorderColor: var(--borderDisabledColor, transparent);
      --innerBorderRightColor: transparent;
    }

    &:hover {
      --innerBorderColor: var(--borderHoverColor, transparent);
      --innerBorderRightColor: var(--borderHoverColor, transparent);
    }
  }
}
