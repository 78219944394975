.form-control-icon-right {
  position: absolute;
  transform: translateY(40%);
  top: 0;
  right: 0.5rem;
}

// TODO preparation for new UI
.form-control-reduce-space {
  //border-radius: 0rem !important;
  //margin: 0;
  //color: black !important;
}

.form-control-locked {
  padding-right: 2.5rem;
  min-height: 39px;
  height: auto;
  background-color: #f5f5f5;
  word-break: break-word;
}

.form-control-as-label {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto;
}
