.custom-status-single-value {
  .fas {
    text-shadow: none;
    color: var(--iconOptionColor, var(--iconLocalColor));
  }

  span {
    color: var(--textColor, initial);
  }
}
