//
// Loading
//

.dialog {
  //$width: 130px;
  //width: $width;
  //margin: 0 auto;
  //position: fixed;
  //z-index: 1100;
  //justify-content: center;
  //padding:  1rem;
  //box-shadow: 0px 3px 12px 0px rgba(0,0,0,0.1);
  //background-color: #fff;
  //@include border-radius($border-radius);

  // Placement
  &.dialog-bottom-center {
    display: flex;
    bottom: -100px;
    left: 50%;
    transform: translateX(-50%);
    transition: bottom 0.6s ease;
    &.dialog-shown {
      bottom: 100px;
      transition: bottom 0.6s ease;
    }
  }
}
