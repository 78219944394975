.tasks-board {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &--content {
    display: flex;
    flex-grow: 1;

    &.no-bottom {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &--top-shadow,
  &--bottom-shadow {
    position: sticky;
    z-index: 1;
    width: 100%;

    min-height: 0.7rem;

    &.hide {
      opacity: 0;
    }
  }

  &--top-shadow {
    top: 2.4rem;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0.7) 65%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &--bottom-shadow {
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0.7) 65%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
