// 3rd-party Plugins
//@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

@import "./styles/_dialog.scss";
@import "./styles/react-select";
@import "./styles/colors";
@import "./styles/tag";
@import "./styles/react-datepicker";

// Default Layout themes
// @import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// // Light
// .brand-light {
//   @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
// }

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

// .nav-link:focus {
//     outline: none;
// }

.upload-file-container {
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  //background: #c8dadf;
  //outline: 2px dashed #ddd;
  //outline-offset: -4px;
  flex-direction: column;
  padding: 10px;
  border-radius: 4px;
  border: 2px dashed #ddd;
  cursor: default;
  line-height: 16px;

  &:focus {
    outline: none;
  }

  &--disabled {
    background: #f3f6f9;
    cursor: progress;
  }

  &--error {
    border-color: $danger;
  }
}

.upload-file-preview {
  &:focus {
    outline: none;
  }
}

a,
a:not([href]),
span.href {
  color: #6993ff;
  text-decoration: none;
  background-color: transparent;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease,
    box-shadow 0.15s ease;
  cursor: pointer;
  outline: 0 !important;

  &:hover {
    color: #1d5cff;
  }
}

.ck-editor__editable {
  min-height: 500px;
}

.modal-content {
  overflow: hidden;
}

.modal-overflow-visible .modal-content {
  overflow: visible;
}

.custom-popper-zindex {
  z-index: 100000 !important;
}

.animation-blink {
  animation: animationBlink 1s steps(5, start) infinite;
}

@keyframes animationBlink {
  to {
    visibility: hidden;
  }
}

@media (max-width: 768px) {
  .w-sm-100 {
    width: 100%;
  }
}
