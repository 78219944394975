.stackedbars {
  position: relative;
  display: flex;

  --borderRadius: 10px;

  --colorPlanned: var(--plannedColor, #ebecf0); // same from the jira progress background color
  --colorDone: var(--doneColor, #50ab9f); // bg-success
  --colorStuck: var(--stuckColor, #e8534f); // bg-danger
  --colorInProgress: var(--inProgressColor, #ffa800); // bg-warning

  &,
  & > :first-child {
    border-top-left-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);
  }

  &,
  & > :last-child,
  & > :nth-last-child(2) {
    border-top-right-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
  }

  .DONE,
  .STUCK,
  .IN_PROGRESS,
  .PLANNED {
    position: relative;
    height: 1rem;
    width: var(--progress, 0);

    &:after,
    &:before {
      display: none;
      position: absolute;
      bottom: -1.5rem;
      left: 3px;
    }

    &:after {
      content: attr(data-progress);
    }

    &:before {
      content: attr(data-progress-amount);
    }
  }

  &.percentages {
    &:hover {
      .DONE,
      .STUCK,
      .IN_PROGRESS,
      .PLANNED {
        &:after {
          display: block;
        }
      }
    }
  }

  &.numbers {
    &:hover {
      .DONE,
      .STUCK,
      .IN_PROGRESS,
      .PLANNED {
        &:before {
          display: block;
        }
      }
    }
  }

  &,
  .PLANNED {
    background-color: var(--colorPlanned);
  }

  .DONE {
    background-color: var(--colorDone);
  }

  .STUCK {
    background-color: var(--colorStuck);
  }

  .IN_PROGRESS {
    background-color: var(--colorInProgress);
  }
}
