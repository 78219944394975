.view-signatory {
  .status {
    font-weight: 500;
    font-size: 0.95em;
    letter-spacing: 0.025em;

    &.pending {
      color: var(--color-status-needed);
    }

    &.viewed {
      color: var(--color-status-in-progress);
    }

    &.rejected {
      color: var(--color-danger);
    }

    &.signed {
      color: var(--color-status-done);
    }
  }
}
