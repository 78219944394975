.select-available-user {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;

  .select {
    flex-grow: 1;
    margin-right: 0.5rem;
  }

  .actions {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
