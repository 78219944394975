div.tag {
  border-radius: 3px;
  color: black;
  font-size: 13px;
  overflow: hidden;
  padding: 0.3em 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: #d7dbf1;
  font-weight: 400;
  min-width: 8px;
  max-width: 130px;
  margin-right: 4px;
  display: inherit;
}
div.tags-column {
  max-width: 192px;
  display: inline-block;
}
div.tags-column > div:last-of-type {
  margin-right: 0px;
}
