@import "../../../../../../_metronic/_assets/sass/init";

.myCustomDate-1 {
  .react-datepicker__month-container {
    width: 250px;
    font-size: 1rem;
    border: none;
  }
  .react-datepicker__header {
    background: $primary-light !important ;
    padding-top: 4px;
  }
  .react-datepicker__month-select {
    padding: 4px;

    font-size: 1.1rem !important;
    border: 1px solid #e1dfe0;
    border-radius: 0.45rem;
  }

  .react-datepicker__year-select {
    padding: 4px;
    font-size: 1.1rem !important;
    border: 1px solid #e1dfe0;
    border-radius: 0.45rem;
  }

  .react-datepicker__navigation-icon {
    color: $white !important;
  }

  .react-datepicker__day--keyboard-selected {
    background: $primary !important;
  }
  .react-datepicker__day--selected {
    background: $primary !important;
  }

  .react-datepicker__current-month {
    color: $black;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 8px;
  }

  .react-datepicker__time-list-item--selected {
    background-color: $primary !important;
  }
}
