//
// Header
//

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Base
  .card-header {
    margin-top: 10px;
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Base
  .card-header {
    margin-top: 10px;
  }
}
