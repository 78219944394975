$vis-label-size: 180px;
$vis-border-radius: 5px;

$color_hex__black: #000000;
$color_hex__planned: #ebecf0;
$color_hex__in-progress: #ffa800;
$color_hex__stuck: #e8534f;
$color_hex__done: #50ab9f;
$color_hex__lane: rgb(252, 252, 252);

$color_hex__vis-border: rgb($color_hex__black, 0.15);

$color_hex__vis-label: #343a40;
$color_hex__vis-axis-label: #6c757d;
$color_hex__vis-current-time: lighten(#cea87b, 10);

$color_hex__vis-label__group-level-0: rgb($color_hex__black, 0.01);
$color_hex__vis-label__group-level-1: rgb($color_hex__black, 0.02);
$color_hex__vis-label__group-level-2: rgb($color_hex__black, 0.06);
