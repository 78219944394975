.ck.ck-editor {
  max-width: 100%;
  max-height: 30%;
}

.ck.ck-editor__editable_inline {
  min-height: 275px;
}

.ck.ck-toolbar {
  background-color: transparent !important;
  border-radius: 0.45rem 0.45rem 0 0 !important;
  box-shadow: none !important;
  outline: 0 !important;
  border-color: #e4e6ef !important;
}

.ck.ck-content {
  border-radius: 0 0 0.45rem 0.45rem !important;
  border-color: #e4e6ef !important;
}

.ck.ck-content.ck-focused {
  //the default blue color, but disabled here as it looks weird
  //border-color: #9cb8ff !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.datepicker-close-button {
  position: absolute;
  right: 24px;
  top: calc(50% - 8px);
}

/* Note: this is a temporary solution, meant to prevent green border and check-mark everytime the user leaves
 a <Field/> after something has been entered.
 If no validation is done on a form field, it makes no sense to apply validation-like styles.
 For now, this is exclusively applied for TaskEditDialog.js, to prevent impact on other parts of the application.
 -> To globally change this default styles related to input fields, the css can be adapted in style.react.scss
   .form-control is-valid .was-validated .form-control:valid, .form-control.is-valid
 */

.no-validation {
  background-image: none !important;
  border-color: #e4e6ef !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.opacity {
  opacity: 0.4;
}
