/*
  WARNING: css variable defined on the React Component

  breaking points
    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

  https://getbootstrap.com/docs/4.5/layout/overview/#responsive-breakpoints
*/

.view-signatures-request {
  $breakpoint-md-max: 991.98px;
  $breakpoint-lg: 992px;
  $breakpoint-lg-max: 1199.98px;

  display: flex;
  flex-direction: column;

  header {
    display: flex;

    margin-bottom: 1.5rem;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      align-self: flex-end;
    }

    .date {
      font-weight: 500;

      &.requested {
        color: var(--color-status-needed);
      }

      &.cancelled {
        color: var(--color-danger);
      }

      &.sealed {
        color: var(--color-status-done);
      }
    }

    .title {
      font-size: 1.25rem;
      font-weight: 500;
    }

    .title,
    .notification-value {
      line-height: 1.5;
    }

    .infos-container {
      display: flex;
      gap: 1.5rem;
      margin-right: 1rem;
    }
  }

  footer {
    font-size: 0.9rem;

    margin-top: 0.5rem;

    .footer-label {
      margin-right: 0.3rem;
    }
  }

  main.collection {
    display: flex;
    flex-direction: column;

    padding-right: 8px;

    overflow: auto;
    position: relative;

    .item-header {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: var(--color-header-bg);
    }
  }

  .item-header {
    color: var(--color-header-text);
    font-weight: 600;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;

    min-height: var(--collection-header-min-height);

    .name:after {
      content: var(--label-name);
    }

    .email:after {
      content: var(--label-email);
    }

    .phone:after {
      content: var(--label-phone);
    }

    .status:after {
      content: var(--label-status);
    }
  }

  .item {
    align-items: center;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 5rem 2fr 2fr 14rem 2fr;

    border-bottom: 1px solid var(--color-border);

    &.last {
      border-bottom: none;
    }

    .attribute {
      padding: 2px;
    }

    .avatar {
      display: flex;
      justify-content: center;
      height: 100%;
    }

    &:not(.item-header) {
      min-height: var(--collection-item-min-height);

      .avatar,
      .name,
      .email,
      .phone,
      .status {
        /* top | right | bottom | left */
        padding: 0.5rem 0.5rem 0.5rem 0;
      }
    }

    @media (max-width: $breakpoint-md-max) {
      grid-template-columns: 5rem 2fr 1fr;

      .email,
      .phone {
        display: none;
        visibility: hidden;
      }
    }

    @media (min-width: $breakpoint-lg) and (max-width: $breakpoint-lg-max) {
      grid-template-columns: 5rem 2fr 2fr 2fr;

      .phone {
        display: none;
        visibility: hidden;
      }
    }
  }
}
