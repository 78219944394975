.modal-dialog-file-viewer {
  max-width: 1600px; /* Set your desired width */
  width: 95vw;
  height: 80%;
}

.modal-body-file-viewer {
  padding: 0;
  height: 80vh;
}
