//
// Bullet
//

// Variables
$bullet-border-radius: 2rem;
$bullet-hor-width: 10px;
$bullet-hor-height: 2px;
$bullet-ver-width: 2px;
$bullet-ver-height: 10px;
$bullet-dot-size: 4px;
$bullet-dot-size: 4px;
$bullet-bar-width: 4px;

// Base
.bullet {
  // Base
  display: inline-block;
  background-color: $gray-300;
  width: $bullet-hor-width;
  height: $bullet-hor-height;
  @include border-radius($bullet-border-radius);

  // Dot bullet
  &.bullet-dot {
    width: $bullet-dot-size;
    height: $bullet-dot-size;
    border-radius: 100% !important;
  }

  // Vertical bullet
  &.bullet-ver {
    width: $bullet-ver-width;
    height: $bullet-ver-height;
  }

  // Vertical resizable bullet(use with align-self-stretch)
  &.bullet-bar {
    width: $bullet-bar-width;
    height: auto;
  }
}
