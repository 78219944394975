// Fix style for creatable of react-select to match bootstrap style
.creatable-select {
  &__control {
    min-height: 0 !important;
    border-color: $input-border-color !important;

    &--is-focused {
      box-shadow: $input-box-shadow !important;
      border-color: $input-focus-border-color !important;
    }
  }

  &__indicator {
    padding: 4px !important;
  }

  &__option {
    cursor: pointer;

    &--is-selected {
      background-color: $primary !important;
    }

    &--is-focused:not(&--is-selected) {
      background-color: $gray-300 !important;
    }

    &:hover {
      background-color: $gray-300;
    }
  }
}

// Create small style to match form-control-sm
.creatable-select-sm {
  &__control {
    height: calc(1.35em + 1.1rem + 2px);
    //padding: 0.55rem 0.75rem;
    font-size: 0.925rem;
    line-height: 1.35;
    border-radius: $input-border-radius-sm !important;
    min-height: 0 !important;
    border-color: $input-border-color !important;

    &--is-focused {
      box-shadow: $input-box-shadow !important;
      border-color: $input-focus-border-color !important;
    }
  }

  &__value-container {
  }

  &__indicator-separator {
    background-color: $input-border-color !important;
  }

  &__indicator {
    padding: 4px !important;
  }

  &__option {
    cursor: pointer;

    &--is-selected {
      background-color: $primary !important;
    }

    &--is-focused:not(&--is-selected) {
      background-color: $gray-300 !important;
    }

    &:hover:not(&--is-selected) {
      background-color: $gray-300;
    }
  }
}

.creatable-select-container.is-invalid {
  .creatable-select-sm__control {
    border-color: $danger !important;
  }
}

.react-select-container.is-invalid {
  .react-select__control {
    border-color: $danger;
  }
}

//color: #3f4254;
//background-color: #ffffff;
//border-color: #9cb8ff;
//outline: 0;
